<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-data-table v-else :item-value="itemValue" :item-text="itemText" :search="companySearch" :items="this.companies" dense class="elevation-1 mt-5" :headers="this.headers">
            <template v-slot:top>
                <v-toolbar flat height="auto" class="py-3" >
                    <v-col>
                        <v-row no-gutters>
                            <v-col class="mt-3 mx-3">
                                <v-row class="mb-3">
                                    <v-toolbar-title class="font-weight-bold text-h4">
                                        Company
                                    </v-toolbar-title>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="mt-3">
                                    <v-text-field class="mx-2" dense outlined clearable v-model="companySearch" label="Search">
                                    </v-text-field>
                                    <v-select class="mx-2" @change="fetch" dense outlined :items="verificationStatusOption" v-model="verificationStatus">
                                    </v-select>
                                </v-row>
                                <v-row>
                                    <v-spacer>

                                    </v-spacer>
                                    <v-btn color="red" @click="redirectCleanCompany" plain outlined>
                                        Clean company
                                    </v-btn>
                                        
                                </v-row>

                            </v-col>
                            <v-alert v-if="isVerificationStatusUpdated" type="success" dismissible>
                                Succesfully update company verification status
                            </v-alert>
                        </v-row>


                    </v-col>

                </v-toolbar>
            </template>

            <template v-slot:item.index="{item}">
                {{ companies.indexOf(item)+1 }}
            </template>

            <template v-slot:item.verification="{item}">
                <div class="pt-4 pb-0 mb-0 d-flex justify-center align-center">

                    <v-select @change="updateCompanyVerification(item)" class="pt-0 mb-0 d-flex align-center" dense outlined :items="verificationStatusOption" v-model="item.verification">
                    </v-select>
                </div>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AConfirmation
},
    computed: mapState({
        itemValue(){
            return (item,index)=>index;
        },
        itemText(){
            return (item,index)=>`${index + 1}. ${item.name}`;
        }
    }),
    props:[
    //
    ],
    data: () => ({
        companySearch:null,
        isPending:false,
        verificationStatusOption :['valid','invalid','internal','unknown'],
        headers:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'Id',
                value:'company_id'
            },
            {
                text:'Company',
                value:'name',
            },
            {
                text:'Created_at',
                value:'created_at',
            },
            {
                text:'Verification Status',
                value:'verification',
            },
         
        ],
        
        companies:null,
        isVerificationStatusUpdated:false,
        verificationStatus:'valid',
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getCompaniesByVerificationStatus"){
                this.companies = resp.data;
            }
            if(resp.class=="updateCompanyValidity"){
                this.isVerificationStatusUpdated =true;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchAllCompaniesApi = this.fetchAllCompanies();
            this.$api.fetch(fetchAllCompaniesApi);
        },
        updateCompanyVerification(company){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/verification/"+company.verification;
            tempApi.params={
                'id':company.company_id,
            };
            this.$api.fetch(tempApi);

        },
        fetchAllCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/verification/"+this.verificationStatus;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"";
        },
        redirectCleanCompany(){
            this.$router.push({name:'PageDeveloperCompaniesClean'})
        },
    }
}
</script>